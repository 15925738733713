import { Component, OnInit, OnChanges, ViewEncapsulation, ViewChild, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarMonthViewDay, CalendarView, CalendarDateFormatter, DAYS_OF_WEEK, CalendarMonthViewComponent } from 'angular-calendar';
import { CustomDateFormatter } from './custom-date-formatter.provider';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

export interface CalendarSelecteDay {
  day: Date;
  color: 'red' | 'green' | 'gray' | 'yellow';
}

@Component({
  selector: 'app-calendar-dates-select',
  templateUrl: './calendar-dates-select.component.html',
  styleUrls: ['./calendar-dates-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarDatesSelectComponent implements OnInit, OnChanges {
  @ViewChild(CalendarMonthViewComponent) calendarMonthViewComponent?: CalendarMonthViewComponent;
  _viewDate: Date = new Date();
  @Input()
  set viewDate(v: Date) {
    this._viewDate = v;
    this.viewDateChange.emit(v);
  }
  get viewDate() {
    return this._viewDate;
  }
  view: CalendarView = CalendarView.Month;

  refresh: Subject<any> = new Subject();

  locale: string = 'es-MX';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];

  @Input()
  selectedDays?: CalendarSelecteDay[];
  @Output()
  selectedDayChange: EventEmitter<Date> = new EventEmitter();
  @Output()
  viewDateChange: EventEmitter<Date> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDays']) {
      if (changes['selectedDays'].currentValue) {
        if(this.calendarMonthViewComponent) {
          this.renderSelectedDays(this.calendarMonthViewComponent.view.days);
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.calendarMonthViewComponent) {
      this.renderSelectedDays(this.calendarMonthViewComponent.view.days);
    }
  }

  dayClicked(day: CalendarMonthViewDay): void {
    this.selectedDayChange.emit(day.date);
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    this.renderSelectedDays(body);
  }

  renderSelectedDays(days: CalendarMonthViewDay[]) {
    days.forEach((day) => {
      const selectedDay = this.selectedDays?.find(
        (selectedDay) => selectedDay.day.getTime() === day.date.getTime()
      );
      if (selectedDay) {
        day.cssClass = selectedDay.color === 'gray' ? 'cal-day-selected' : `cal-day-selected-${selectedDay.color}`;
      }
    });
  }

}
