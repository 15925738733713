import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material/form-field';
import { NgxFormlyMaterialUploadOptions } from '../file-upload.service';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface FileProps {
  uploadOptions?: NgxFormlyMaterialUploadOptions;
  hideRemove?: boolean;
}

@Component({
  selector: 'ngx-formly-material-file',
  templateUrl: './ngx-formly-material-file.component.html',
  styleUrls: ['./ngx-formly-material-file.component.scss'],
})
export class FormlyMatFileComponent<F extends FormlyFieldConfig<FormlyFieldProps & FileProps>> extends FieldType<F> {
  get fileFormControl(): UntypedFormControl {
    if (this.formControl) {
      return this.formControl as UntypedFormControl;
    } else {
      return new UntypedFormControl(0);
    }
  }
  
  override defaultOptions: any = {
    props: {
      floatLabel: 'always'
    },
  };

  constructor() {
    super();
  }

  get uploadOptions(): NgxFormlyMaterialUploadOptions | undefined {
    if (!this.field.props || !this.field.props['uploadOptions']) {
      return undefined;
    }
    return this.field.props['uploadOptions'];
  }

  get hideRemove(): boolean {
    if (!this.field.props) {
      return false;
    }
    return !!this.field.props['hideRemove'];
  }
}
