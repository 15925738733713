import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { ExportButtonComponent } from "./export-button.component";

@NgModule({
  imports: [
    MatButtonModule,
    MatMenuModule,
  ],
  declarations: [
    ExportButtonComponent,
  ],
  exports: [
    ExportButtonComponent,
  ]
})
export class ExportButtonModule {}