import { Component, KeyValueChanges, KeyValueDiffer, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material/form-field';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface TimeProps {
  defaultLocation?: [number, number];
  restrictionBounds?: google.maps.LatLngBoundsLiteral;
}

@Component({
  selector: 'app-ngx-formly-material-location',
  templateUrl: './ngx-formly-material-location.component.html',
  styleUrls: ['./ngx-formly-material-location.component.scss']
})
export class FormlyMatLocationComponent extends FieldType<FormlyFieldConfig<FormlyFieldProps & TimeProps>> implements OnInit {
  get locationFormControl(): UntypedFormControl {
    if (this.formControl) {
      return this.formControl as UntypedFormControl;
    } else {
      return new UntypedFormControl(0);
    }
  }

  override defaultOptions: any = {
    templateOptions: {
      floatLabel: 'always'
    },
  };

  defaultLocation!: [number, number];
  restrictionBounds!: google.maps.LatLngBoundsLiteral;

  private toDiffer?: KeyValueDiffer<string, any>;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  toChanged(changes: KeyValueChanges<string, any>) {
    /* If you want to see details then use
      changes.forEachRemovedItem((record) => ...);
      changes.forEachAddedItem((record) => ...);
      changes.forEachChangedItem((record) => ...);
    */
    this.defaultLocation = this.props['defaultLocation'] || [0,0];
    this.restrictionBounds = this.props['restrictionBounds'] as google.maps.LatLngBoundsLiteral;
  }

  ngDoCheck(): void {
    const changes = this.toDiffer?.diff(this.props);
    if (changes) {
      this.toChanged(changes);
    }
  }

}
