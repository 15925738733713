import { FormlyFieldConfig } from '@ngx-formly/core';
import { NgxFormlyMaterialUploadOptions } from '../components';

export const AMENITY_COMMON_FIELDS: FormlyFieldConfig[] = [
  {
    key: 'sectionsIds',
    type: 'chips',
    className: 'col-xs-1-2',
    props: {
      label: 'Secciones',
      placeholder: 'Secciones',
      required: true,
    },
    expressions: {
      hide: '!formState.isNew'
    }
  },
  {
    key: 'sectionsNames',
    type: 'read-only',
    className: 'col-xs-1-2',
    props: {
      label: 'Secciones',
      placeholder: 'Secciones'
    },
    expressions: {
      hide: 'formState.isNew'
    }
  },
  {
    key: 'name',
    type: 'input',
    className: 'col-xs-1-2',
    props: {
      label: 'Nombre',
      placeholder: 'Nombre',
      required: true,
    }
  },
  {
    key: 'description',
    type: 'textarea',
    className: 'col-xs-1-2',
    props: {
      label: 'Descripción',
      placeholder: 'Descripción',
      required: true,
      autosize: true
    }
  },
  {
    key: 'startTime',
    type: 'time',
    className: 'col-xs-1-2',
    props: {
      label: 'Hora de inicio',
      placeholder: 'Hora de inicio',
      required: true,
    }
  },
  {
    key: 'endTime',
    type: 'time',
    className: 'col-xs-1-2',
    props: {
      label: 'Hora máxima',
      placeholder: 'Hora máxima',
      required: true,
    }
  },
  {
    key: 'durations',
    type: 'select',
    className: 'col-xs-1-2',
    props: {
      label: 'Duraciones',
      placeholder: 'Duraciones',
      required: true,
      multiple: true,
      options: [
        { label: '30 minutos', value: (30 * 60 * 1000) },
        { label: '1 hora', value: (60 * 60 * 1000) },
        { label: '1.5 horas', value: (1.5 * 60 * 60 * 1000) },
        { label: '2 horas', value: (2 * 60 * 60 * 1000) },
        { label: '3 horas', value: (3 * 60 * 60 * 1000) },
        { label: '4 horas', value: (4 * 60 * 60 * 1000) },
        { label: '5 horas', value: (5 * 60 * 60 * 1000) },
      ],
    }
  },
  {
    key: 'capacity',
    type: 'input',
    className: 'col-xs-1-2',
    props: {
      type: 'number',
      label: 'Capacidad',
      placeholder: 'Capacidad',
      required: true,
    },
    validators: {
      validation: ['number']
    },
  },
  {
    key: 'cost',
    type: 'input',
    className: 'col-xs-1-2',
    props: {
      type: 'number',
      label: 'Costo',
      placeholder: 'Costo',
      required: true,
    },
    validators: {
      validation: ['number']
    },
  },
  {
    key: 'image',
    type: 'file',
    className: 'col-xs-2-2',
    props: {
      label: 'Imagen',
      placeholder: 'Imagen',
      required: true,
      uploadOptions: {
        uploadUrl: '',
        method: 'PUT'
      } as NgxFormlyMaterialUploadOptions
    }
  },
  {
    key: 'status',
    type: 'select',
    className: 'col-xs-2-2',
    props: {
      label: 'Estatus',
      placeholder: 'Estatus',
      required: true,
      options: [
        { value: 'ACTIVE', label: 'Activo' },
        { value: 'INACTIVE', label: 'Inactivo' },
      ]
    }
  }
];

export const AMENITY_EXTRA_FIELDS = [
  {
    key: 'name',
    type: 'input',
    className: 'col-xs-1-2',
    props: {
      label: 'Nombre',
      placeholder: 'Nombre',
      required: true,
    }
  },
  {
    key: 'cost',
    type: 'input',
    className: 'col-xs-1-2',
    props: {
      type: 'number',
      label: 'Precio',
      placeholder: 'Precio',
      required: true,
    }
  },
];

export const AMENITY_DOCUMENT_FIELDS = [
  {
    key: 'name',
    type: 'input',
    className: 'col-xs-2-2',
    props: {
      label: 'Nombre',
      placeholder: 'Nombre',
      required: true,
    }
  },
  {
    key: 'file',
    type: 'file',
    className: 'col-xs-2-2',
    props: {
      label: 'Archivo',
      placeholder: 'Archivo',
      required: true,
      uploadOptions: {
        uploadUrl: '',
        method: 'PUT'
      } as NgxFormlyMaterialUploadOptions
    }
  },
];