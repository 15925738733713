import { Injectable, Optional, Inject, InjectionToken } from '@angular/core';
import { DynamicDialogService } from '../components/dynamic-dialog';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApolloError } from '@apollo/client/core';
import { GraphQLError } from 'graphql';

export interface ApolloErrorHandlerConfig {
  errorMessage?: string;
  errorAction?: string;
  production?: boolean;
}

export const APOLLO_ERROR_HANDLER_CONFIG = new InjectionToken<ApolloErrorHandlerConfig>('HTTP_ERROR_HANDLER_CONFIG');


export enum ApolloErrorHandlerType {
  SILENCE = 0,
  DISCREET = 1,
  NOSY = 2
}

export type HandleError =
  <T> (operation?: string, result?: T, type?: ApolloErrorHandlerType) => (error: ApolloError) => Observable<T>;

export type CodeListener = (error: ApolloError) => void;

export type CodeListeners = { [key: string]: CodeListener};

export const DEFAULT_ERROR_MESSAGE = 'Error, prueba de nuevo';
export const DEFAULT_ERROR_ACTION = 'OK';

@Injectable({
  providedIn: 'root'
})
export class ApolloErrorHandlerService {
  production: boolean;
  messages: { message: string, type: ApolloErrorHandlerType}[] = [];
  activeMessage: boolean = false;
  constructor(
    private dynamicDialogService: DynamicDialogService,
    private matSnackBar: MatSnackBar,
    @Optional() @Inject(APOLLO_ERROR_HANDLER_CONFIG) private config?: ApolloErrorHandlerConfig
  ) {
    this.production = config?.production || false;
  }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => <T>
    (
      operation = 'operation',
      result = null as T,
      type: ApolloErrorHandlerType = 0
    ) => this.handleError(serviceName, operation, result, type)

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(serviceName = '', operation = 'operation', result = null as T, type: ApolloErrorHandlerType = 0) {

    return (error: ApolloError): Observable<T> => {
      if (!this.production) {
        console.error(`Error: ${serviceName} - ${operation}`, error);
      }
      this.handleApolloErrorMessage(error, type);
      // Let the app keep running by returning a safe result.
      return of(result);
    };
  }

  private handleApolloErrorMessage(error: ApolloError | Error | any, type: ApolloErrorHandlerType) {
    // console.log('error', error);
    // console.log('error error?.message', error?.message);
    if (error?.message) {
      const mssg: string = error.message;
      // console.debug('Error message:', mssg);
      if (mssg.match(/.*USERMSG.*/)) {
        this.handleErrorMessage(mssg.replace(/\r?\n/g, '').replace(/.*USERMSG: /, '') || DEFAULT_ERROR_MESSAGE, type);
        return;
      }
    }
    this.handleErrorMessage(DEFAULT_ERROR_MESSAGE, type);
  }


  private handleErrorMessage(message: string, type: ApolloErrorHandlerType) {
    // console.log('handleErrorMessage', message);
    if (this.activeMessage) {
      this.messages.push({message, type});
    } else {
      this.activeMessage = true;
      this.showErrorMessage(message, type);
    }
  }

  private showErrorMessage(message: string, type: ApolloErrorHandlerType){
    // console.log('showErrorMessage', message);
    const errorMessageBase = (this.config && this.config.errorMessage) ?
      this.config.errorMessage : DEFAULT_ERROR_MESSAGE;
    const errorAction = (this.config && this.config.errorAction) ?
      this.config.errorAction : DEFAULT_ERROR_ACTION;
    message = message ? message : errorMessageBase;
    switch (type) {
      case ApolloErrorHandlerType.SILENCE:
        this.validateMessagesStack();
        break;
      case ApolloErrorHandlerType.DISCREET:
        this.openSnackBar(message, errorAction);
        break;
      case ApolloErrorHandlerType.NOSY:
        this.openDialog(message);
        break;
      default:
        break;
    }
  }

  private validateMessagesStack() {
    if (this.messages.length) {
      const nextMessage = this.messages.pop();
      if (nextMessage) {
        this.showErrorMessage(nextMessage.message, nextMessage.type);
      }
    } else {
      this.activeMessage = false;
    }
  }

  private openSnackBar(message: string, errorAction: string){
    // console.log('openSnackBar', message);
    const snackBar = this.matSnackBar.open(message, errorAction, {
      duration: 3000,
    });
    snackBar.afterDismissed().subscribe(_ => {
      this.validateMessagesStack();
    });
  }

  private openDialog(message: string) {
    // console.log('openDialog', message);
    const dialog = this.dynamicDialogService.showErrorDialog(message);
    dialog.afterClosed().subscribe(_ => {
      this.validateMessagesStack();
    });
  }

}
