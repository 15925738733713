import { ChangeDetectorRef, Component, DoCheck, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild  } from '@angular/core';
import {
  formatPercent, formatDate, formatNumber, formatCurrency
} from '@angular/common';
import { FieldType } from '@ngx-formly/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FloatLabelType } from '@angular/material/form-field';

export interface ReadOnlyProps {
  pipe?: { name: string; params?: any[]; multiply?: boolean; };
  filter?: (value: any, model: any ) => any;
  map?: { [key: string]: any };
  view?: ((value: any) => any[]) | { route: (value: any) => any[]; relativeTo?: boolean; };
  useSpan?: boolean;
  floatLabel?: FloatLabelType;
}

@Component({
  selector: 'app-ngx-formly-material-read-only',
  templateUrl: './ngx-formly-material-read-only.component.html',
  styleUrls: ['./ngx-formly-material-read-only.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyMatReadOnlyComponent extends FieldType<FieldTypeConfig<FormlyFieldProps & ReadOnlyProps>> implements OnInit, DoCheck {
  // @ViewChild('matSuffix')
  // override matSuffix!: TemplateRef<any>;
  readOnlyValue: string;
  noneValue: any;

  override defaultOptions = {
    props: {
      floatLabel: 'always' as FloatLabelType,
    },
  };
  PIPES_MAP: { [key: string]: (value: any, ...args: any) => string };

  constructor(
    private changeDetectorReg: ChangeDetectorRef,
    @Inject(LOCALE_ID) private locale: string,
    private router: Router, private activatedRoute: ActivatedRoute
  ) {
    super();
    this.readOnlyValue = '';
    this.PIPES_MAP = {
      percentage: formatPercent,
      date: formatDate,
      number: formatNumber,
      currency: formatCurrency
    };
  }

  ngOnInit(): void {
    this.noneValue = (this.props.type === 'number' || this.props.pipe?.name === 'number') ? 0 : '-';
    this.readOnlyValue = this.noneValue;
    this.formControl.valueChanges.subscribe(_ => {
      this.changeDetectorReg.detectChanges();
    });
  }

  ngAfterContentChecked() {
    // if (this.matSuffix){
    //   this.props.suffix = this.matSuffix;
    // }
  }

  ngDoCheck(): void {
    // const changes = this.toDiffer?.diff(this.to);
    // if (changes) {
    //   this.toChanged(changes);
    // }
  }

  getReadOnlyValue() {
    // console.log('getReadOnlyValue', this.to);
    let value =this.formControl.value;
    if (!value && this.field.defaultValue) {
      value = this.field.defaultValue;
    }
    // console.log('getReadOnlyValue value', value);
    if (this.props.filter) {
      // console.log('formControl this.props.filter', this.field.key, this.props.filter);
      value = this.props.filter(value, this.model);
    }
    if (this.props.map) {
      value = this.props.map[value];
    }
    if (value && this.props.pipe) {
      const pipeSelected = this.props.pipe;
      const pipe = this.PIPES_MAP[pipeSelected.name];
      // console.log('pipe', pipe);
      if (pipe) {
        // console.log('pipeSelected', pipeSelected);
        // console.log('pipeSelected.params', pipeSelected.params);
        // console.log('pipeSelected.params', ...pipeSelected.params);
        if (!pipeSelected.params) {
          pipeSelected.params = [];
        }
        if (pipeSelected.name === 'date') {
          value = pipe(value, pipeSelected.params[0] ?? 'EEEE dd-MM-yyyy', this.locale, ...(pipeSelected.params.slice(1, pipeSelected.params.length - 1)));
        } else {
          if (pipeSelected.name === 'percentage') {
            if (pipeSelected.multiply === null || pipeSelected.multiply === undefined || pipeSelected.multiply === true) {
              value = pipe(value, this.locale, ...pipeSelected.params);
            } else {
              value = formatNumber(value, this.locale, ...pipeSelected.params) + ' %';
            }
          } else {
            value = pipe(value, this.locale, ...pipeSelected.params);
          }
        }
      }
    }
    this.readOnlyValue = value;
    if (!this.readOnlyValue) {
      this.noneValue = (this.props.type === 'number' || this.props.pipe?.name === 'number' || this.props.pipe?.name === 'currency') ? 0 : '-';
      this.readOnlyValue = this.noneValue;
    }
    // console.log('getReadOnlyValue', this.field.key, this.readOnlyValue);
    return this.readOnlyValue;
  }

  view(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.props.view) {
      const value = this.formControl.value;
      if (typeof this.props.view === 'function'){
        this.router.navigate(this.props.view(value));
      } else {
        if (this.props.view.relativeTo) {
          this.router.navigate(this.props.view.route(value), { relativeTo: this.activatedRoute });
        } {
          this.router.navigate(this.props.view.route(value));
        }
      }
    }
  }
}
