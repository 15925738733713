<div class="frow text-center">
    <div class="col-xs-1-3">
        <button mat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
            Anterior
        </button>
        <!-- <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Today
        </div> -->
    </div>
    <div class="col-xs-1-3">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es-MX' }}</h3>
    </div>
    <div class="col-xs-1-3">
        <button mat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
            Siguiente
        </button>
    </div>
</div>
<mwl-calendar-month-view
    [viewDate]="viewDate"
    [refresh]="refresh"
    [locale]="locale"
    [weekStartsOn]="weekStartsOn"
    [weekendDays]="weekendDays"
    (beforeViewRender)="beforeMonthViewRender($event)"
    (dayClicked)="dayClicked($event.day)">
</mwl-calendar-month-view>