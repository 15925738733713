import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import localeEs from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

import { CalendarModule } from 'angular-calendar';
import { CalendarDatesSelectComponent } from './calendar-dates-select.component';
import { MatButtonModule } from '@angular/material/button';

registerLocaleData(localeEs, 'es-MX');

@NgModule({
  declarations: [
    CalendarDatesSelectComponent,

  ],
  imports: [
    CommonModule,
    CalendarModule,
    MatButtonModule,
  ],
  exports: [
    CalendarDatesSelectComponent
  ]
})
export class CalendarDatesSelectModule { }
