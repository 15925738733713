import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogService } from '../../../components/dynamic-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '../../services/auth.service';
import { AppConfig } from 'projects/tauns-admin/src/app/app.config';

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent implements OnInit {
  authForm = new UntypedFormGroup({});
  authFields: FormlyFieldConfig[] = [{
    key: 'email',
    type: 'input',
    // defaultValue: 'admin@mail.com',
    validators: {
      validation: ['email']
    },
    className: 'bold',
    templateOptions: {
      label: 'Email',
      placeholder: 'Email',
      required: true,
      type: 'email',
    }
  }];
  siteKey = AppConfig.reCaptchaKey;
  lang = 'es-419';
  validCaptcha = false;
  isOperator: boolean = false;

  constructor(
    private dynamicDialogService: DynamicDialogService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    this.authForm = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    this.authService.logout().subscribe();
    this.isOperator = this.authService.validateIsOperator(this.router.url);
  }

  ngOnInit(): void {
  }

  handleSuccess() {
    this.validCaptcha = true;
  }

  handleExpire() {
    this.validCaptcha = false;
  }

  onSubmit() {
    let { email } = this.authForm.value;
    this.dynamicDialogService.showLoadingDialog();
    this.authService.requestPassword({ email }, this.isOperator).subscribe(async res => {
      console.log(res);
      if (res) {
        await this.router.navigate(['/auth/login']);
      }
      this.dynamicDialogService.hideLoadingDialog();
      this.dynamicDialogService.showMessageDialog('Enviado', 'Revisa tu correo para recuperar tu contraseña.');
    });
  }

}
