import { Component, EventEmitter, Output } from "@angular/core";

export enum ExportType {
  PDF = 'PDF',
  CSV = 'CSV',
}

@Component({
  selector: 'app-export-button',
  template:`
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">Exportar</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="export.emit(ExportType.PDF)">PDF</button>
      <button mat-menu-item (click)="export.emit(ExportType.CSV)">CSV</button>
    </mat-menu>

  `,
})
export class ExportButtonComponent {
  @Output() export: EventEmitter<ExportType> = new EventEmitter<ExportType>();
  ExportType = ExportType;
}